
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
@Component({
	name: 'BStates',
	components: {},
	data() {
		return {}
	}
})
export default class BStates extends Vue {
	@Prop({ default: '' }) private default?: ''
	@Prop({ country: 'US' } as any) private country?: ''
	private loading = false
	private state = ''
	private stateList = []
	private filterStateData = []
	@Watch('default')
	onDefault(value: string) {
		if (value) this.state = value
	}
	@Watch('country')
	onCountry(value: string) {
		if (value) this.getState()
	}
	@Watch('state')
	onState(value: string) {
		this.$emit('input', value)
	}
	filterState(keyword = '') {
		keyword = keyword.trim()
		if (!keyword) return (this.filterStateData = this.stateList)
		this.filterStateData = this.stateList.filter((item: any) => {
			return (
				item.name.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
				item.id.toLowerCase().indexOf(keyword.toLowerCase()) > -1
			)
		})
	}
	async getState() {
		this.loading = true
		const { data } = await this.$axios
			.get('//res-cn.public.gblinker.com/gblinker/wechat_mini/data/states.json')
			.finally(() => {
				this.loading = false
			})
		const country = this.country || 'US'
		this.stateList = data[country].states
		this.state = ''
		this.filterState()
	}
	created() {
		this.getState()
	}
}
