
import { Component, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import BStates from '@/components/biz/b-states.vue'
import Emitter from '@/utils/emitter'
import { getUserRole } from '@/utils/utils'

@Component({
	components: { BStates }
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
		'address-form': ElForm
	}
	private userRoles: any = {}
	private isLoading = false
	private isEmpty = false
	private searchForm = {
		userCode: '',
		packageNumber: '',
		orderCode: ''
	}
	private countryData: any = {}
	@Watch('searchForm.userCode')
	onInput(value: string) {
		this.isEmpty = !value
		this.userData.user_code = ''
	}
	getCountryIcon(country: string) {
		return this.countryData[country] ? this.countryData[country]['icon'] : ''
	}
	get countryList() {
		return Object.values(this.countryData)
	}

	async checkIsOrder(logisticsCode: string) {
		const { data } = await this.$axios
			.get(`/v1/jobline/packages/get-order/${logisticsCode}`, {
				params: {
					logisticsCode
				}
			})
			.catch(() => {
				return {
					data: null
				}
			})

		if (data?.package_num) {
			console.log(data)
			this.$message.error('快递单号已下单，请勿重复下单!')
		}
	}

	onRouteChange() {
		const { user_code }: any = this.$route.query

		if (user_code) {
			this.searchForm.userCode = user_code
			this.searchUser()
		}
	}
	async getUserRole() {
		this.userRoles = await getUserRole()
	}
	formatUserRole(role: number) {
		return this.userRoles[role]
	}

	private addressList = []
	private addressDialog = {
		visible: false,
		address: {
			address1: '',
			address2: '',
			city: '',
			country: 'US',
			firstname: '',
			is_default: 0,
			lastname: ' ',
			mobile: '',
			state: '',
			zipcode: ''
		}
	}

	private userHabit = {
		pay_package_type: {
			with_in_day: 60,
			merge_total: 0,
			transmit_total: 0
		}
	}
	private roleDialogVisible = false

	private userData = {
		id: null,
		info: {
			avatarUrl: ''
		},
		user_code: '',
		code: '',
		role: 0
	}
	private packageForm = {
		addressId: null,
		ship_from: {
			firstname: '',
			lastname: '',
			mobile: '',
			country: 'US',
			state: 'AL',
			city: '',
			zipcode: '',
			address1: '',
			address2: ''
		},
		logistics_code: '',
		declare_amount: 1000,
		express_id: ''
	}
	private packageNote = []
	private validatePackageForm = {
		addressId: [
			{ required: true, message: '请选择客户收件人地址', trigger: 'change' }
		],
		declare_amount: [
			{ required: true, message: '请输入申报金额', trigger: 'blur' }
		],
		logistics_code: [
			{ required: true, message: '请输入国内快递单号', trigger: 'blur' }
		],
		express_id: [{ required: true, message: '请选择线路', trigger: 'change' }]
	}
	private validateAddress = {
		firstname: [
			{ required: true, message: '请输入收件人姓名', trigger: 'blur' }
		],
		country: [
			{ required: true, message: '请选择国家或地区', trigger: 'change' }
		],
		zipcode: [{ required: true, message: '请输入邮编', trigger: 'blur' }],
		state: [{ required: true, message: '请选择州', trigger: 'change' }],
		city: [{ required: true, message: '请输入城市', trigger: 'blur' }],
		address1: [{ required: true, message: '请输入地址1', trigger: 'blur' }]
	}

	private expressList = []

	created() {
		this.getUserRole()
		this.onRouteChange()
		this.getState()
	}

	async getAllExpressList(country = '') {
		const { data } = await this.$axios
			.get('/v1/express/getexpresslist', {
				params: {
					country
				}
			})
			.catch(() => {
				return {
					data: null
				}
			})
		this.expressList = data.map((item: any) => {
			const selected =
				item.title.includes('普货小包') || item.title.includes('普货空运')
			if (selected && !this.packageForm.express_id) {
				this.packageForm.express_id = item.id
			}
			return {
				...item,
				selected: selected
			}
		})
	}

	async selectAddress(item: Record<string, string>) {
		if (!item) return
		for (const key in item) {
			;(this.packageForm.ship_from as any)[key] = item[key]
		}
		;(this.packageForm as any).addressId = item.id
		this.packageForm.express_id = ''
		await this.getAllExpressList(item.country)
		this.$refs['form'].validate().catch(() => false)
	}
	async addAddress() {
		const isValidate = await this.$refs['address-form']
			.validate()
			.catch(() => false)
		if (!isValidate) return
		const { id: user_id } = this.userData
		const postData = {
			...this.addressDialog.address,
			user_id: user_id
		}
		const { data } = await this.$axios
			.post('/v1/jobline/user/address', postData)
			.catch(() => {
				return {
					data: null
				}
			})

		if (data) {
			this.$message.success('添加地址成功')
			this.addressDialog.visible = false
			this.searchUser()
		}
	}

	async checkbBeforeSubmit() {
		if (!this.countryData) {
			await this.getState()
		}
		const {
			ship_from: { country = 'US' }
		} = this.packageForm
		const { miniprogram_order_plugins = [] } = this.countryData[country] || {}
		if (miniprogram_order_plugins.length) {
			for (let index = 0; index < miniprogram_order_plugins.length; index++) {
				const plugin = miniprogram_order_plugins[index]
				if (plugin.required) return false
			}
		}
		return true
	}

	async savePackage() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const isCheck = await this.checkbBeforeSubmit().catch(() => false)
		if (!isCheck) {
			this.$message.error('不能代客户下单!')
			return
		}
		const { id: user_id } = this.userData

		const selectNoteValue = this.packageNote.join(',')

		const postData = {
			...this.packageForm,
			user_id: user_id,
			note: selectNoteValue
		}
		const { data } = await this.$axios.post(
			'/v1/jobline/package/create',
			postData
		)

		if (data) {
			this.$message.success('创建成功')
			this.resetForm()
		}
	}
	async resetForm() {
		this.expressList = []
		this.packageForm['addressId'] = null
		this.packageForm['express_id'] = ''
		this.packageForm['ship_from'] = {
			firstname: '',
			lastname: '',
			mobile: '',
			country: 'US',
			state: 'AL',
			city: '',
			zipcode: '',
			address1: '',
			address2: ''
		}
		this.packageForm['logistics_code'] = ''
		this.packageForm['declare_amount'] = 99
		this.addressDialog.address = {
			address1: '',
			address2: '',
			city: '',
			country: 'US',
			firstname: '',
			is_default: 0,
			lastname: ' ',
			mobile: '',
			state: '',
			zipcode: ''
		}
		this.packageNote = []
	}
	async getState() {
		const { data } = await this.$axios.get(
			'//res-cn.public.gblinker.com/gblinker/wechat_mini/data/states.json'
		)
		this.countryData = data
	}
	async searchUser() {
		const { userCode } = this.searchForm
		if (!userCode) {
			this.$message.warning('请输入需要查询的用户编号')
			return
		}
		if (this.isLoading) {
			return
		}
		this.resetForm()
		this.isLoading = true
		const { data } = await this.$axios
			.get('/v1/jobline/find/user', {
				params: {
					user_code: userCode
				}
			})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))
		this.$router
			.push({
				query: {
					user_code: userCode
				}
			})
			.catch(err => err)
		if (!data) {
			this.isEmpty = true
			this.userData.id = null
			return
		}
		this.userData = data.user
		this.user_role = data.user.role
		this.addressList = data.addresses
		this.userHabit = data.user_habit
		this.addressList.sort((a: any, b: any) => b.is_default - a.is_default)
		this.isEmpty = false
	}

	async closeRoleDialog() {
		this.roleDialogVisible = false
		this.searchUser()
	}
	private user_role = 0
	async updateUserRole() {
		const { code: user_code } = this.userData
		this.isLoading = true
		const { data } = await this.$axios
			.put('/v1/jobline/user/role', {
				user_code: user_code,
				role: this.user_role
			})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))
		if (data) this.$message.success('更新成功')
		setTimeout(() => this.closeRoleDialog(), 1000)
	}
}
