var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "z-page",
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "el-form",
            {
              ref: "search-form",
              staticClass: "search-form",
              attrs: { inline: true, model: _vm.searchForm },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                  return (() => {}).apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户编号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "用户编号/电话号码", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.searchUser.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.searchForm.userCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.searchForm,
                          "userCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "searchForm.userCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "z-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.searchUser },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.userData.id
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.isLoading,
                  expression: "isLoading",
                },
              ],
              staticClass: "content",
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 30 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8, xs: 24, sm: 10, md: 8 } },
                    [
                      _c("el-row", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "container info-card" }, [
                          _c("div", { staticClass: "info-header" }, [
                            _c(
                              "svg",
                              {
                                staticClass: "icon",
                                attrs: { "aria-hidden": "true" },
                              },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#icon-icon_user" },
                                }),
                              ]
                            ),
                            _c("h1", { staticClass: "title" }, [
                              _vm._v("客户信息"),
                            ]),
                          ]),
                          _c("div", { staticClass: "info-body" }, [
                            _c("div", { staticClass: "info-item" }, [
                              _c("label", { staticClass: "info-item-label" }, [
                                _vm._v("姓名"),
                              ]),
                              _c("div", { staticClass: "info-item-content" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.userData.name || "-") + " "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "info-item" }, [
                              _c("label", { staticClass: "info-item-label" }, [
                                _vm._v("用户编码"),
                              ]),
                              _c(
                                "div",
                                { staticClass: "info-item-content" },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.userData.code)),
                                  ]),
                                  _c(
                                    "el-link",
                                    {
                                      attrs: {
                                        underline: false,
                                        target: "_blank",
                                        href:
                                          "/user/packages?user_code=" +
                                          _vm.userData.code,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            round: "",
                                            plain: "",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("包裹列表")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "info-item" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "info-item-label" },
                                  [_vm._v("客户类型")]
                                ),
                                _c(
                                  "el-tag",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: _vm.formatUserRole(
                                        _vm.userData.role
                                      ).tag_type,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.formatUserRole(_vm.userData.role)
                                          .name
                                      )
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "修改角色",
                                      placement: "top-start",
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "el-icon-edit",
                                      staticStyle: {
                                        cursor: "pointer",
                                        "margin-left": "20px",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.roleDialogVisible = true
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "info-item" }, [
                              _c("label", { staticClass: "info-item-label" }, [
                                _vm._v("头像"),
                              ]),
                              _vm.userData.info
                                ? _c(
                                    "div",
                                    { staticClass: "info-item-content" },
                                    [
                                      _vm.userData.info
                                        ? _c("img", {
                                            staticClass: "avatar",
                                            attrs: {
                                              src: _vm.userData.info.avatarUrl,
                                            },
                                          })
                                        : _c("p", [_vm._v("-")]),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "info-item" }, [
                              _c("label", { staticClass: "info-item-label" }, [
                                _vm._v("手机号码"),
                              ]),
                              _c("div", { staticClass: "info-item-content" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.userData.mobile || "-") + " "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "info-item" }, [
                              _c("label", { staticClass: "info-item-label" }, [
                                _vm._v("推荐人"),
                              ]),
                              _c("div", { staticClass: "info-item-content" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.userData.parent_user
                                        ? _vm.userData.parent_user.name +
                                            "(" +
                                            _vm.userData.parent_user.user_code +
                                            ")"
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "info-item" }, [
                              _c("label", { staticClass: "info-item-label" }, [
                                _vm._v(
                                  "邮寄习惯(近" +
                                    _vm._s(
                                      _vm.userHabit.pay_package_type.with_in_day
                                    ) +
                                    "天内)"
                                ),
                              ]),
                              _c("div", { staticClass: "info-item-content" }, [
                                _vm._v(
                                  " 直邮包裹: " +
                                    _vm._s(
                                      _vm.userHabit.pay_package_type
                                        .transmit_total
                                    ) +
                                    " / 集运包裹: " +
                                    _vm._s(
                                      _vm.userHabit.pay_package_type.merge_total
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 16, xs: 24, sm: 14, md: 16 } },
                    [
                      _c(
                        "div",
                        { staticClass: "container info-card" },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "form",
                              staticClass: "info-form",
                              attrs: {
                                model: _vm.packageForm,
                                rules: _vm.validatePackageForm,
                                "label-width": "80px",
                              },
                            },
                            [
                              _c("div", { staticClass: "info-header" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "icon",
                                    attrs: { "aria-hidden": "true" },
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "#icon-icon_address",
                                      },
                                    }),
                                  ]
                                ),
                                _c("h1", { staticClass: "title" }, [
                                  _vm._v("用户地址"),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "add-address-btn",
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function ($event) {
                                        _vm.addressDialog.visible = true
                                      },
                                    },
                                  },
                                  [_vm._v("添加地址")]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "info-body" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "address-list",
                                      attrs: {
                                        "label-width": "0",
                                        prop: "addressId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.addressList,
                                      function (item, idx) {
                                        return _c(
                                          "div",
                                          {
                                            key: idx,
                                            staticClass: "address-item",
                                            class: {
                                              "is-active":
                                                _vm.packageForm.addressId ==
                                                item.id,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectAddress(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "line-item" },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "icon",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#icon-icon_user1",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  { staticClass: "text" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.firstname) +
                                                        " " +
                                                        _vm._s(item.lastname) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "line-item" },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "icon",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#icon-icon_phone",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  { staticClass: "text" },
                                                  [_vm._v(_vm._s(item.mobile))]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "line-item" },
                                              [
                                                _c(
                                                  "svg",
                                                  {
                                                    staticClass: "icon",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  },
                                                  [
                                                    _c("use", {
                                                      attrs: {
                                                        "xlink:href":
                                                          "#icon-icon_address1",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "p",
                                                  { staticClass: "text" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.address1) +
                                                        " " +
                                                        _vm._s(
                                                          item.address2 || ""
                                                        ) +
                                                        ", " +
                                                        _vm._s(item.city) +
                                                        ", " +
                                                        _vm._s(item.state) +
                                                        " " +
                                                        _vm._s(item.zipcode) +
                                                        ", " +
                                                        _vm._s(item.country) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm.getCountryIcon(item.country)
                                              ? _c("img", {
                                                  staticClass: "country-icon",
                                                  attrs: {
                                                    src: _vm.getCountryIcon(
                                                      item.country
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            item.is_default
                                              ? _c(
                                                  "div",
                                                  { staticClass: "is-default" },
                                                  [_vm._v(" 默认地址 ")]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "info-header" }, [
                                _c(
                                  "svg",
                                  {
                                    staticClass: "icon",
                                    attrs: { "aria-hidden": "true" },
                                  },
                                  [
                                    _c("use", {
                                      attrs: {
                                        "xlink:href": "#icon-icon_order_info",
                                      },
                                    }),
                                  ]
                                ),
                                _c("h1", { staticClass: "title" }, [
                                  _vm._v("填写订单信息"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "info-body order-info-form" },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-input",
                                      attrs: {
                                        label: "国内快递单号",
                                        "label-width": "150px",
                                        prop: "logistics_code",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "填写国内快递单号",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.checkIsOrder(
                                              _vm.packageForm.logistics_code
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.packageForm.logistics_code,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.packageForm,
                                              "logistics_code",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "packageForm.logistics_code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-input",
                                      attrs: {
                                        label: "申报金额 / RMB",
                                        "label-width": "150px",
                                        prop: "declare_amount",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: { placeholder: "填写申报金额" },
                                        model: {
                                          value: _vm.packageForm.declare_amount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.packageForm,
                                              "declare_amount",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "packageForm.declare_amount",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "form-item-input",
                                      attrs: {
                                        label: "线路",
                                        prop: "express_id",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder: "请选择线路",
                                            filterable: "",
                                          },
                                          model: {
                                            value: _vm.packageForm.express_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.packageForm,
                                                "express_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "packageForm.express_id",
                                          },
                                        },
                                        _vm._l(
                                          _vm.expressList,
                                          function (item, idx) {
                                            return _c("el-option", {
                                              key: idx,
                                              attrs: {
                                                value: item.id,
                                                label: item.title,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "订单备注",
                                        "label-width": "100px",
                                      },
                                    },
                                    [
                                      _c("el-checkbox", {
                                        attrs: { label: "需要合包" },
                                        model: {
                                          value: _vm.packageNote,
                                          callback: function ($$v) {
                                            _vm.packageNote = $$v
                                          },
                                          expression: "packageNote",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ctrl-btn" },
                [
                  _c(
                    "z-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.savePackage },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.isEmpty && this.searchForm.userCode
        ? _c("div", { staticClass: "empty" }, [
            _c("img", {
              attrs: {
                src: "https://res-cn.public.gblinker.com/gblinker/ops_assets/img_no_result.svg",
              },
            }),
            _c("p", { staticClass: "message" }, [_vm._v("未查询到此客户")]),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "address-dialog",
          attrs: { width: "480px", visible: _vm.addressDialog.visible },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.addressDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("添加地址")]
          ),
          _c(
            "el-form",
            {
              ref: "address-form",
              staticClass: "info-form",
              attrs: {
                model: _vm.addressDialog.address,
                rules: _vm.validateAddress,
                "label-width": "120px",
              },
            },
            [
              _c(
                "div",
                { staticClass: "inline-from" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item-input",
                      attrs: { label: "收件人", prop: "firstname" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "填写收件人" },
                        model: {
                          value: _vm.addressDialog.address.firstname,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addressDialog.address,
                              "firstname",
                              $$v
                            )
                          },
                          expression: "addressDialog.address.firstname",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "form-item-input",
                      attrs: { label: "收件人联系方式", prop: "mobile" },
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addressDialog.address.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.addressDialog.address, "mobile", $$v)
                          },
                          expression: "addressDialog.address.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input",
                  attrs: { label: "国家/地区", prop: "country" },
                },
                [
                  _vm.countryList
                    ? _c(
                        "el-select",
                        {
                          staticClass: "country-item",
                          model: {
                            value: _vm.addressDialog.address.country,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.addressDialog.address,
                                "country",
                                $$v
                              )
                            },
                            expression: "addressDialog.address.country",
                          },
                        },
                        _vm._l(_vm.countryList, function (item, key) {
                          return _c(
                            "el-option",
                            {
                              key: key,
                              attrs: { value: item.id, label: item.name_cn },
                            },
                            [
                              _c("span", { staticStyle: { float: "left" } }, [
                                item.icon
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "12px",
                                        height: "12px",
                                        "margin-right": "5px",
                                      },
                                      attrs: { src: item.icon },
                                    })
                                  : _vm._e(),
                                _vm._v(_vm._s(item.name_cn)),
                              ]),
                            ]
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input",
                  attrs: { label: "邮编", prop: "zipcode" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addressDialog.address.zipcode,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressDialog.address, "zipcode", $$v)
                      },
                      expression: "addressDialog.address.zipcode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input",
                  attrs: { label: "省份/州", prop: "state" },
                },
                [
                  _c("b-states", {
                    attrs: { country: _vm.addressDialog.address.country },
                    model: {
                      value: _vm.addressDialog.address.state,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressDialog.address, "state", $$v)
                      },
                      expression: "addressDialog.address.state",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input",
                  attrs: { label: "城市", prop: "city" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addressDialog.address.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressDialog.address, "city", $$v)
                      },
                      expression: "addressDialog.address.city",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input",
                  attrs: { label: "地址1", prop: "address1" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addressDialog.address.address1,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressDialog.address, "address1", $$v)
                      },
                      expression: "addressDialog.address.address1",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "form-item-input",
                  attrs: { label: "地址2", prop: "address2" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addressDialog.address.address2,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressDialog.address, "address2", $$v)
                      },
                      expression: "addressDialog.address.address2",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "checkbox-item" },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": "1", "false-label": "0" },
                      model: {
                        value: _vm.addressDialog.address.is_default,
                        callback: function ($$v) {
                          _vm.$set(_vm.addressDialog.address, "is_default", $$v)
                        },
                        expression: "addressDialog.address.is_default",
                      },
                    },
                    [_vm._v("设为默认收货地址")]
                  ),
                ],
                1
              ),
              _c(
                "z-button",
                { attrs: { type: "primary" }, on: { click: _vm.addAddress } },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.roleDialogVisible,
            "before-close": _vm.closeRoleDialog,
            title: "修改用户角色",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.roleDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.user_role,
                callback: function ($$v) {
                  _vm.user_role = $$v
                },
                expression: "user_role",
              },
            },
            _vm._l(_vm.userRoles, function (item, idx) {
              return _c("el-radio", { key: idx, attrs: { label: item.role } }, [
                _vm._v(_vm._s(item.name)),
              ])
            }),
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeRoleDialog()
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateUserRole },
                },
                [_vm._v("修 改")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }