var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-select",
    {
      staticClass: "package-state",
      attrs: {
        "popper-class": "package-state-pop",
        filterable: "",
        remote: "",
        "reserve-keyword": "",
        placeholder: "请选择送达州区",
        loading: _vm.loading,
        "remote-method": _vm.filterState,
      },
      model: {
        value: _vm.state,
        callback: function ($$v) {
          _vm.state = $$v
        },
        expression: "state",
      },
    },
    _vm._l(_vm.filterStateData, function (state) {
      return _c(
        "el-option",
        { key: state.id, attrs: { label: state.name, value: state.id } },
        [
          _c("span", { staticClass: "state-name" }, [
            _vm._v(_vm._s(state.name) + _vm._s(state.name_cn)),
          ]),
          _c("span", {
            staticClass: "state-code",
            domProps: { textContent: _vm._s(state.id) },
          }),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }